html,
body {
  width: 100%;
  height: 100%;
}
*:focus {
  outline: none;
}
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
/*!
   * Start Bootstrap - Simple Sidebar (https://startbootstrap.com/template-overviews/simple-sidebar)
   * Copyright 2013-2019 Start Bootstrap
   * Licensed under MIT (https://github.com/BlackrockDigital/startbootstrap-simple-sidebar/blob/master/LICENSE)
   */
body {
  overflow-x: hidden;
  margin: auto;
  /* border-style:dashed;
    border-width: 1px;
    border-color: red; */
}

a {
  color: inherit !important;
  text-decoration: none;
}
@font-face {
  font-family: 'SubwayFootLong';
  src: url('/assets/stormborn/fonts/subway/subwayFootlongOffice.otf')
    format('opentype');
}
@font-face {
  font-family: 'SubwaySixInch';
  src: url('/assets/stormborn/fonts/subway/subwaySixInchOffice.otf')
    format('opentype');
}
